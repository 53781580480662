import React, { useEffect } from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';

function About() {
    useEffect(()=>{
        Aos.init();
    },[]);
    const ScrolltoTop =()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }
  return (
    <div>
      <section class="sect_cls">
        <Header/>
        <div className='Service_body_bg'>
            <div class="container center-vertically12" data-aos="zoom-in-up" data-aos-duration='1500'>
                <div class="text-center">
                    <h1 className='ourservices-text'>About <span style={{color:'#000000'}}>Us</span></h1>
                    <p className='inner-text'>"B-ALERT: India's cutting-edge personal security. Instantly alert <br/>loved ones, police, and ambulances on WhatsApp during emergencies. <br/>Redefining safety with swift, reliable communication for peace of mind."
                    </p>
                    <div className='btn-lg mt-4'>
                        <Link to={'/Contact'} onClick={ScrolltoTop} className='btn button' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px'}}>Get in Touch</Link>
                    </div>
                </div>
            </div>
            </div>
        <section>
            <div class="container mt-5">
                <h1 className='mission'><span className='us'>Our</span> Mission</h1>
                <div class="row">
                    <div class="col-md-3">
                        <h6 className='Text-head' data-aos='fade-right' data-aos-duration='1500'>Unrivaled Security</h6>
                    </div>
                    <div class="col-md-9">
                        <p className='Tail-text' data-aos='fade-left' data-aos-duration='1500'>B-Alert is committed to providing unparalleled personal security solutions. We focus on empowering individuals during emergencies and ensuring a worry-free journey through seamless integration with travel services.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <h6 className='Text-head' data-aos='fade-right' data-aos-duration='1500'>Specific Expertise</h6>
                    </div>
                    <div class="col-md-9">
                        <p className='Tail-text' data-aos='fade-left' data-aos-duration='1500'>B-Alert's core expertise lies in understanding, analyzing, and executing robust personal security strategies, catering to specific user needs and market demands.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <h6 className='Text-head' data-aos='fade-right' data-aos-duration='1500'>Experience</h6>
                    </div>
                    <div class="col-md-9">
                        <p className='Tail-text' data-aos='fade-left' data-aos-duration='1500'>With a track record of safeguarding users, from international travelers to daily commuters, B-Alert has been the trusted choice for instant alerts and integrated security services.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <h6 className='Text-head' data-aos='fade-right' data-aos-duration='1500'>Technology Integration</h6>
                    </div>
                    <div class="col-md-9">
                        <p className='Tail-text' data-aos='fade-left' data-aos-duration='1500'>B-Alert seamlessly combines cutting-edge technology with human-centric design, delivering an optimal balance for reliable and advanced personal security.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container mt-5">
                <h1 className='mission'><span className='us'>Our</span> Vision</h1>
                <div class="row">
                    <div class="col-md-3">
                        <h6 className='Text-head' data-aos='fade-right' data-aos-duration='1500'>Ethics</h6>
                    </div>
                    <div class="col-md-9">
                        <p className='Tail-text' data-aos='fade-left' data-aos-duration='1500'>For B-Alert, ethics mean ensuring user privacy and delivering services tailored to individual needs, creating a sense of trust that our solutions are unique and secure.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <h6 className='Text-head' data-aos='fade-right' data-aos-duration='1500'>Quality Assurance</h6>
                    </div>
                    <div class="col-md-9">
                        <p className='Tail-text' data-aos='fade-left' data-aos-duration='1500'>B-Alert is dedicated to providing advice and features grounded in the best combination of technology, research, and creative solutions, backed by stringent internal quality assurance. </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <h6 className='Text-head' data-aos='fade-right' data-aos-duration='1500'>Long-Term Satisfaction</h6>
                    </div>
                    <div class="col-md-9">
                        <p className='Tail-text' data-aos='fade-left' data-aos-duration='1500'>B-Alert envisions building enduring relationships with users, ensuring their continued satisfaction and the sustained quality of our personal security services.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="mt-5">
                <img src="./images/Home_bar1.png" alt="" width="100%"/>
            </div>
        </section>
        <section>
            <div class="container text-center mt-5 mb-5" data-aos="zoom-in-up" data-aos-duration='1500'>
                <h1 className='here-text'>We’re here for you <br/>
                    no matter where you are</h1>
            </div>
        </section>
        <section>
            <div class="container mt-5 ">
                <img src="./images/map.png" alt="" width="100%" data-aos="flip-up" data-aos-duration='1500'/>
                <hr className='line mb-5'/>
            </div>
        </section>
        <section>
            <div class="container text-center mt-5 mb-5" data-aos="zoom-in-up" data-aos-duration='1500'>
                <h1 className='here-text'>Proudly Supported by</h1>
                <div className='row'>
                    <div className='col-md-4'>
                    <img src='./images/T-Hub.png' width={'60%'}/>
                    </div>
                    <div className='col-md-4 mt-3'>
                    <img src='./images/StartupIndia.png' width={'75%'}/>
                    </div>
                    <div className='col-md-4 mb-5'>
                    <img src='./images/Niti.png' width={'70%'}/>
                    </div>
                </div>
            </div>
        </section>
    </section>
    <Footer/>
    </div>
  )
}

export default About
