import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { BrowserRouter,Link } from 'react-router-dom';

function Privacy() {
  useEffect(()=>{
    AOS.init();
  },[]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div class="sect_cls">
        <Header/>
        <section>
            <div className='Service_body_bg'>
            <div class="container center-vertically12" data-aos="zoom-in-up" data-aos-duration='1500'>
                <div class="text-center">
                    <h1 className='ourservices-text'>Privacy <span style={{color:'#000000'}}>Policy</span></h1>
                    <p className='inner-text'>Your privacy matters. Our robust privacy policies safeguard <br/>your data, ensuring confidentiality and security. Trust us to <br/>prioritize and protect your personal information <br/>throughout your online journey with us.</p>
                    <div className='btn-lg mt-4'>
                        <Link to={'/Contact'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px'}}>Get in Touch</Link>
                    </div>
                </div>
            </div>
            </div>
        </section>
        <section>
            <div class="container mt-5 mb-5">
                <div class="service_background shadow mt-5">
                    <div class="accordion-container">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:'#F9A738'}}>
                                    <p>Introduction</p>
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul> <li><p>
                                        We Buzzler Technologies Pvt Ltd take your privacy very seriously and are committed to protecting your personal data (meaning any information about you from which you can be identified).</p></li>
                                        <li><p> Buzzler Technologies Pvt Ltd is the data controller who is responsible for your personal data (collectively referred to as “we”, “us” or “our”) in this privacy statement. If you do not agree to the processing of your personal data in the way this Privacy Statement describes, please do not provide your personal data. </p></li>
                                        <li><p>This Privacy Statement explains what personal data we collect when you access our website at www.balert.in or engage with us on social media platforms (including our Facebook, Twitter, YouTube, Instagram pages), and/or when you otherwise interact or communicate with us. It also explains how we collect, store or use this data, and what rights you have in relation to such data.</p></li>
                                        <li><p>Buzzler Technologies Pvt Ltd provides an enhanced tool wrapped with WhatsApp Web that allows us to store, manipulate, analyze and transfer messages between us and customer Personal/Business WhatsApp.</p></li>
                                        <li><p>We keep our privacy practices under review and may change this Privacy Statement from time to time by posting changes on the services or otherwise by notifying you. You are also responsible for periodically reviewing any changes which may be made to the Privacy Statement.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{color:'#F9A738'}}>
                                    <p>Collection of Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>We may collect information or pieces of information that could allow you to be identified, including:
                                    </p>
                                    <p> A) Information you provide us directly:</p>
                                    When you register as a user for the online Services (through a paid subscription or trial offer) and/or use the services you provide us with certain information that we store. This may include:
                                    <ol type="a">
                                        <li>
                                            <p>Personal identifiers (such as your name, username, social media name, address, email address, password, and phone number)</p>
                                        </li>
                                        <li>
                                            <p>Device identifiers (such as your carrier identifier)</p>
                                        </li>
                                        <li>
                                            <p>Personal characteristics (such as your date of birth, gender, and the language you speak)</p>
                                        </li>
                                        <li>
                                            <p>Financial information (such as payment card information and UPI address any other)</p>
                                        </li>
                                        <li>
                                            <p>Audio and visual information (such as account photo, other photos, and other materials)</p>
                                        </li>
                                        <li>
                                            <p>Geolocation data and other metadata (such as content creation date, formatting information, and location information (I.e., geotags))</p>
                                        </li>
                                        <li>
                                            <p>Inferences from any of the above categories about your preferences.</p>
                                        </li>
                                    </ol>
                                    <p>B) Information collected from third parties</p>
                                    <ul>

                                        <p>We may receive information about you from third parties who may have collected and transferred your personal data to us in accordance with their own privacy policies and/or terms of use. This may include personal identifiers (such as your name, address, email address, and phone number) and personal characteristics (such as	 your date of birth and gender). Examples of such third-party includes social media networks.</p>
                                        <p>When we receive such information from such third parties, we may combine it with the other personal data that we have collected about you as set out herein and use such information either alone or in combination with the other personal data we hold for the purposes outlined in this Privacy Statement.</p>
                                    </ul>
                                    <p>C)Information we get when you use our services:</p>
                                    <ul>
                                        <p>We collect the technical data automatically as you interact with our services by using cookies and other similar web technologies. When you access our websites or use our WhatsApp enabled chat system, we, our service providers and our business partners may automatically collect information about you, your computer or mobile device, and activity on our websites or mobile applications. Typically, this information includes your computer or mobile operating system, type and version number, manufacturer and model, device identifier (such as Google advertising ID or Apple ID for advertising), browser type, screen resolution, IP address, the website you visited before browsing to our website, general location information such as city, state or geographic area and information about your use of and actions on or in our websites such as pages or screens you accessed, how long you spent on a page or screen, navigation paths between pages or screens, information about your activity on a page or screen, access time and length of access.
                                        </p>
                                        <p>Not all of the lists above will necessarily apply to you – it depends on your use of the services and your particular interaction and communications with us.
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{color:'#F9A738'}}>
                                    <p> Usage of Information Collected From You</p>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>The information collected from you may be used to operate, provide, develop and improve our services. Data like demographic and identity data online identifiers personal data and other technical data is used to manage orders, provide services, deliver products, process payments and communicate with you and to improvise on all our products and services. Here’s what we use your information for:</p>

                                    <ul>
                                        <li>send you technical notices updates, security alerts and support and administrative messages;</li>
                                        <li>verify your identity and provide you access to our services;</li>
                                        <li>respond to your comments questions request and provide customer service;</li>
                                        <li>communicate with you about products, services to provide news and information we think will be of interest to you;
                                        </li>
                                        <li>monitor and analyze trends, usage and activities in connection with our services;</li>
                                        <li>revise, modify, enhance and update existing services and develop new services;</li>
                                        <li>detect, investigate and prevent fraudulent transactions and other illegal activities;</li>
                                        <li>personalize the services and present you with advertising contain or features that we believe will be of interest or useful to you;</li>
                                        <li>link or combine with information we get from others to help us understand your needs and provide you with better services;</li>
                                        <li>enforce our terms of service and other policies; and </li>
                                        <li>carry out any other purpose describe to you at the time the data was collected.</li>
                                    </ul><br />
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{color:'#F9A738'}}>
                                    <p> Sharing Your Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p> We may share your personal data with:</p>
                                    <ol type="a">
                                        <li>
                                            <p>Any member of our Group. The term “Group” shall mean any entity that is controlled by us or any entity that is in control of us or any entity that is under common control with us, whether directly or indirectly.</p>
                                            <p>our employees, vendors, agents and professional advisors working on our behalf for the purposes described in this policy statement; and</p>
                                            <p>service-providers who assist in protecting and securing our systems and provide services to us which require the processing of personal data, such as to host your information or to process your information for data profiling and user analysis.</p>
                                        </li>
                                    </ol>
                                    <p>We usually do not share other personal Data collected from the Website with other third parties. However, this may happen if:</p>
                                    <ol type="a">
                                        <li>
                                            <p> You request or authorize us to do so;</p>
                                            <p> We need to comply with applicable law or respond to valid legal process; or</p>
                                            <p>We need to operate and maintain the security of this website, including to prevent or stop an attack on our computer systems or networks.</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style={{color:'#F9A738'}}>
                                    <p> Use of Cookies And Other Trackers</p>
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>We use temporary and permanent cookies, tags, scripts, and other similar technologies to identify users of our services and to enhance user experience, identify visitors, track website navigation, gather demographic information about visitors and users, understand email campaign effectiveness and for tracking your activities on our websites. We use third party tracking services like “Google Analytics” and (any other) to understand the behavior of our visitors and serve them better. You can set your browser not to accept cookies. However, in a few cases, some of our website features may not function as a result. Please refer to our Cookie Policy for more information.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" style={{color:'#F9A738'}}>
                                    <p> Links to Third Party Websites</p>
                                </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Our website may provide links to third party websites for your information. If you access those links through our website, you will leave our website. The owners and operators of these third-party websites are responsible for their collection and/or use of your personal data and you should check their respective privacy policies before submitting your personal information unless specifically referred to otherwise, this Privacy Policy does not apply to any third-party websites.</p>
                                    <p>WE HEREBY DISCLAIM LIABILITY FOR ANY INFORMATION, MATERIALS, PRODUCTS, OR SERVICES POSTED OR OFFERED AT ANY OF THE THIRD-PARTY SITES LINKED TO THIS WEBSITE. BY CREATING A LINK TO A THIRD-PARTY WEBSITE, WE DO NOT ENDORSE OR RECOMMEND ANY PRODUCTS OR SERVICES OFFERED OR THE INFORMATION CONTAINED AT THAT WEBSITE NOR ARE WE LIABLE FOR ANY FAILURE OF PRODUCT OR SERVICES OFFERED OR ADVERTISED AT THOSE SITES. SUCH THIRD-PARTY MAY HAVE A PRIVACY POLICY DIFFERENT FROM THAT OF OURS AND THE THIRD-PARTY WEBSITE MAY PROVIDE LESS SECURITY THAN THIS SITE.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" style={{color:'#F9A738'}}>
                                    <p> Security of Your Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>We’re committed to protecting our users’ personal data. We implement appropriate technical and organizational measures to help protect the security of your personal data. However, be aware that no system is ever completely secure.</p>
                                    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, altered, disclosed, or accessed in an unauthorized manner. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instruction, and they are subject to a duty of confidentiality. In relation to third–party service providers whom we appoint to process your personal data on our behalf we take steps to ensure that those service providers are contractually bound to protect your personal data.</p>
                                    <p>We have put in place procedures to deal with any suspected data security bridge and will notify you and any applicable regulator where we are legally required to do so.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight" style={{color:'#F9A738'}}>
                                    <p> Retention of Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Buzzler Technologies retains personal data for as long as necessary to provide the access to and use of the website, or for other essential purposes such as complying with our legal obligations, resolving disputes and enforcing our agreements. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly.</p>
                                    <p>Even if we delete your data, it may persist on backup or archival media for audit, legal, tax or regulatory purposes.</p>
                                    <p>In general terms, we will retain your personal data for the duration of your involvement/ engagement with us and for as long as reasonably necessary afterwards. However, we may maintain different retention periods for different products and services. There are also certain types of information that are required to be retained for a certain period by law. If you close or request that we close your account, we’ll delete or anonymize your personal data so that it no longer identifies you, unless we’re required to keep something, or we still need to use it for a legally justifiable reason.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine" style={{color:'#F9A738'}}>
                                    <p> Your Individual Legal Rights</p>
                                </button>
                            </h2>
                            <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Our company would like to make sure you are fully aware of all of your data protection rights.  Below, you will find additional privacy information that you may find important. Our company adheres to applicable data protection laws and the Information Technology Act, 1500, as amended or substituted, which if applicable includes the following rights in relation to your Data:</p>
                                    <ol type="a">
                                        <li>
                                            <p> Right to access - You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                                            <p>Right to rectification - You have the right to request to correct any information you believe is inaccurate. You also have the right to request complete information you believe is incomplete.</p>
                                            <p>Right to erasure- You have the right to request to erase your personal data, under certain conditions.</p>
                                            <p>Right to restriction - You have the right to request to restrict the processing of your personal data, under certain conditions.</p>
                                            <p>Right to data portability - You have the right to request to transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                                            <p>Right to object - You have the right to object to processing your personal data, under certain conditions.</p>
                                        </li>
                                    </ol>
                                    <p>It is important that the data we hold about you is accurate and current. Please keep us informed if your data changes during the period for which we hold it. Requests should be made in writing, and we may ask you to verify your identity along with your request. If you make a request, we have one month to respond to you. We will not charge your fee to exercise your rights unless your request is clearly unfounded or excessive, in which case we may charge you a reasonable fee. Alternatively, we may refuse to comply with the request in such circumstances.</p>
                                    <p> If you would like to exercise any of these rights, please contact us at our email:</p>
                                    <p>Call us at:</p>
                                    <p>Or write to us:</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen" style={{color:'#F9A738'}}>
                                    <p> Contact Us</p>
                                </button>
                            </h2>
                            <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Please contact Buzzler Technologies if:</p>
                                    <ol type="a">
                                    <li>
                                        <p> you have any questions about this Privacy statement, our practices relating to the website;</p>
                                        <p> you wish to exercise one of your data protection rights; or</p>
                                        <p> you otherwise have a question or complaint about the manner in which the data we hold on to you.</p>
                                    </li>
                                    </ol>
                                    <p>You can reach us by emailing: hello@buzzler.in</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default Privacy