import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { BrowserRouter,Link } from 'react-router-dom';

function Cancelation() {
  useEffect(()=>{
    AOS.init();
  },[]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div class="sect_cls">
        <Header/>
        <section>
            <div className='Service_body_bg'>
            <div class="container center-vertically12" data-aos="zoom-in-up" data-aos-duration='1500'>
                <div class="text-center">
                    <h1 className='cancel-text'>Cancellation and <span style={{color:'#000000'}}>Refund Policy</span></h1>
                    <p className='inner-text'>Flexible cancellation policies designed for your <br/>convenience. We understand plans can change. Enjoy <br/>peace of mind with our transparent and accommodating <br/>cancellation policies for a worry-free experience.</p>
                    <div className='btn-lg mt-4'>
                        <Link to={'/Contact'} onClick={scrollToTop} className='btn' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px'}}>Get in Touch</Link>
                    </div>
                </div>
            </div>
            </div>
        </section>
        <section>
            <div class="container mt-5 mb-5">
                <div class="service_background shadow mt-5">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <p style={{color:'#F9A738'}}>Definitions and key terms</p>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>
                                    To help explain things as clearly as possible in this Return & Refund Policy, every
                                    time any of
                                    these terms
                                    are
                                    referenced, are strictly defined as:
                                <ul>
                                    <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to
                                        balert,
                                        that is
                                        responsible for your information under this Return & Refund Policy.</li>
                                    <li>Customer: refers to the company, organization or person that signs up to use the
                                        balert
                                        Service to
                                        manage the relationships with your consumers or service users.</li>
                                    <li>Device: any internet connected device such as a phone, tablet, computer or any
                                        other device
                                        that can
                                        be
                                        used to visit balert and use the services.</li>
                                    <li>Service: refers to the service provided by balert as described in the relative
                                        terms (if
                                        available)
                                        and
                                        on this platform.</li>
                                    <li>Website: balert’s site, which can be accessed via this URL: www.balert.in</li>
                                    <li>You: a person or entity that is registered with balert to use the Services.</li>
                                </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <p style={{color:'#F9A738'}}>Cancellation Policy</p>
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <h5>
                                    Order Cancellation by the Customer:
                                </h5><br/>
                                <p>Customers can request the cancellation of their order before the payment process is
                                    completed. To
                                    cancel
                                    an
                                    order, the customer must contact our customer support team through the provided
                                    channels on our
                                    website.
                                    Once the payment process is completed, the order cannot be canceled.
                                </p><br/>
                                <h5>
                                    Order Cancellation by BUZZLER TECHNOLOGIES PRIVATE LIMITED:
                                </h5><br/>
                                <p>BUZZLER TECHNOLOGIES PRIVATE LIMITED reserves the right to cancel an order in the
                                    following
                                    circumstances:</p>
                                <ul>
                                    <li>
                                        <p>
                                            Insufficient information provided by the customer during the order process.
                                        </p>
                                    </li>

                                    <li>
                                        <p>Payment authorization is not received or declined.</p>
                                    </li>
                                    <li>
                                        <p>fraudulent activity related to the transaction.</p>
                                    </li>
                                    <li>
                                        <p>Technical issues or errors during the payment process.</p>
                                    </li>
                                    <li>
                                        <p>Any other valid reason at the discretion of BUZZLER TECHNOLOGIES PRIVATE
                                            LIMITED.</p>
                                    </li>
                                    <li>
                                        <p>In the event of an order cancellation by BUZZLER TECHNOLOGIES PRIVATE
                                            LIMITED, customers
                                            will be
                                            notified promptly, and any payment made for the canceled order will be
                                            refunded
                                            according to the
                                            Refund Policy outlined in Section 2.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <p style={{color:'#F9A738'}}>Refund Policy</p>
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <h5>
                                    Eligibility for Refunds:
                                </h5><br/>
                                <ul>
                                    <li>Refunds will be considered in the following cases:</li>
                                    <li>Order cancellation by the customer before payment completion.</li>
                                    <li>Failed transactions due to technical errors or payment gateway issues.</li>
                                    <li>Order cancellation by BUZZLER TECHNOLOGIES PRIVATE LIMITED (as outlined in
                                        Section 1.2).
                                    </li>
                                </ul><br/>
                                <h5>
                                    Refund Process:
                                </h5><br/>
                                <ul>
                                    <li>To request a refund, customers must contact our customer support team through
                                        the provided
                                        channels
                                        on
                                        our website. The refund request will be processed within 7 days of receiving the
                                        request.
                                        The refund
                                        will be issued using the original payment method used by the customer during the
                                        order
                                        process.
                                    </li>
                                </ul>
                                <br/>
                                <h5>
                                    Refund Amount:
                                </h5><br/>
                                <ul>

                                    <li>In the case of order cancellation by the customer before payment completion, the
                                        full order
                                        amount
                                        will
                                        be refunded.</li>
                                    <li>For failed transactions due to technical errors or payment gateway issues, the
                                        full order
                                        amount
                                        will be
                                        refunded.</li>
                                    <li>If BUZZLER TECHNOLOGIES PRIVATE LIMITED cancels the order for any valid reason,
                                        the full
                                        order
                                        amount
                                        will be refunded.</li>

                                </ul>
                                <br/>
                                <h5>
                                    Non-Refundable Products/Services:
                                </h5><br/>
                                <ul>
                                    <li>Certain products or services offered by BUZZLER TECHNOLOGIES PRIVATE LIMITED may
                                        be
                                        non-refundable.
                                        Customers will be clearly notified of such cases before making the payment.</li>
                                </ul>
                                <br/>
                                <h5>
                                    Refund Timeframe:
                                </h5><br/>
                                <ul>
                                    <li>The time required to process the refund and for the refunded amount to reflect
                                        in the
                                        customer's
                                        account
                                        may vary depending on the payment method and the customer's bank. Generally,
                                        refunds are
                                        processed
                                        within 48 hours, but it may take additional time for the refund to appear in the
                                        customer's
                                        account.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <p style={{color:'#F9A738'}}>Your Consent</p>
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>By using our website, registering an account, or making a purchase, you hereby
                                    consent to our
                                    Return &
                                    Refund
                                    Policy and agree to its terms.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <p style={{color:'#F9A738'}}>Changes To Our Return & Refund Policy</p>
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>Should we update, amend or make any changes to this document so that they accurately reflect our
                                    Service
                                    and
                                    policies. Unless otherwise required by law, those changes will be prominently posted here. Then,
                                    if you
                                    continue
                                    to use the Service, you will be bound by the updated Return & Refund Policy. If you do not want
                                    to agree
                                    to
                                    this
                                    or any updated Return & Refund Policy, you can delete your account.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                <p style={{color:'#F9A738'}}>Contact Us</p>
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>If, for any reason, You are not completely satisfied with any service that we provide, don't
                                    hesitate to
                                    contact
                                    us and we will discuss any of the issues you are going through with our product.</p>
                                <ul>
                                    <li>Via Email: hello@buzzler.in</li>
                                    <li>Via Phone Number: (+91) 91547 91547</li>
                                    <li>Via this Address: T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao Nagar,Madhapur, Hyderabad, Telangana 500081</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default Cancelation