import React, { useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter, Link } from "react-router-dom";
function Home() {
  useEffect(() => {
    AOS.init();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div class="sect_cls">
        <Header />
        <section>
          <div class="container">
            <div class="row">
              <div
                class="col col-md-12 col-xl-7 col-xxl-7 col-lg-7 col-sm-12 col-xs-12 mt-5"
                data-aos="zoom-in-right"
                data-aos-duration="1000"
              >
                <h1 className="home1-text">
                  India’s First{" "}
                  <span style={{ color: "#000000" }}>Personal</span>
                  <br />
                  <span className="home2-text">Travel Guardian.</span>
                </h1>
                <p className="introduce-text">
                  Introducing B-ALERT: India's leading personal security
                  software. Safeguard your journeys with instant alerts.
                  Seamless integration with travel insurance, hotels, route
                  maps, ensuring comprehensive safety for your personal trips.
                </p>
                <div class="d-flex mt-5">
                  <img src="./images/icons.png" alt="" className="icon_Round" />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <h4 className="ten">
                    10K+ <span className="user">Happy Users</span>
                  </h4>
                </div>
                <div class="download_background mt-5">
                  <p class="app-text border">Download our app</p>
                  <div class="store">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.balert"
                      target="_blank"
                    >
                      <img
                        src="./images/google play.png"
                        alt=""
                        className="app_Store_Img"
                      />
                    </a>
                    &nbsp;&nbsp;
                    <img
                      src="./images/app store.png"
                      alt=""
                      className="app_Store_Img"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 col-xl-5 col-lg-5 col-xxl-5 col-sm-12 col-xs-12"
                data-aos="zoom-in-left"
                data-aos-duration="1000"
              >
                <img
                  src="./images/herosection-img.png"
                  className="herosection"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="home_bg">
            <div class="container center-vertically">
              <div class="row">
                <div
                  class="col-md-12 col-xl-7 col-lg-7 col-sm-12 col-xs-12"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <h2 className="solution-text">
                    One stop for the <br />
                    automobile solutions
                  </h2>
                  <div class="d-flex">
                    <p className="hundred lh-sm">
                      100k <span className="hundred">+</span>
                    </p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p className="hundred lh-sm">4.7</p>
                  </div>
                  <div className="d-flex Download-rating">
                    <p className="small-text">Downloads</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p className="small-text">Ratings</p>
                  </div>
                </div>
                <div
                  class="col-md-12 col-xl-5 col-lg-5 col-sm-12 col-xs-12"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div class="d-flex inner_home">
                    <img
                      src="./images/Home_image1.png"
                      alt=""
                      className="unique_Image"
                    />
                    <div style={{ marginLeft: "2em" }}>
                      <h3 className="unique">Unique Target</h3>
                      <p className="inner-text">
                        Clear target is to ensure our customer's journey is safe
                        and secure.
                      </p>
                    </div>
                  </div>
                  <div class="d-flex inner_home1">
                    <img
                      src="./images/Home_image2.png"
                      alt=""
                      className="unique_Image"
                    />
                    <div style={{ marginLeft: "2em" }}>
                      <h3 className="unique">Comfortable progress</h3>
                      <p className="inner-text">
                        Our reach will get completed with the loyal services and
                        comfortable onboarding.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="background">
            <div>
              <div class="container">
                <div class="row">
                  <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                    <img
                      src="./images/B_Image.png"
                      alt=""
                      className="B_Image"
                      data-aos="zoom-in-down"
                      data-aos-duration="1500"
                    />
                    <br />
                    <img
                      src="./images/Alert_Image.png"
                      alt=""
                      className="ALERT_Image"
                      data-aos="zoom-in-up"
                      data-aos-duration="1500"
                    />
                    <hr></hr>
                  </div>
                  <div
                    class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    <h1 className="about-text">About</h1>
                    <p className="introduce-text">
                      The objective of B-Alert is to provide a comprehensive
                      personal security solution, prioritizing user safety
                      during emergencies. Seamlessly integrating with various
                      services, B-Alert aims to ensure a secure and worry-free
                      journey for users.
                    </p>
                    <div className="btn-lg mt-4">
                      <Link
                        to={"/Contact"}
                        onClick={scrollToTop}
                        className="btn btn-md"
                        style={{
                          backgroundColor: "#F9A738",
                          color: "#ffffff",
                          fontSize: "1rem",
                        }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container">
            <h1 className="focus-text">Main Focus/Mission</h1>
            <div class="row">
              <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-around">
                  <img src="./images/1.png" alt="" className="two-image" />
                  <p
                    className="inner-text1"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    B-Alert's main focus is to pioneer personal security
                    solutions, offering instant alerts and seamless integration
                    with travel services. We prioritize user safety, ensuring a
                    protected and worry-free experience.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="d-flex justify-content-around">
                  <img src="./images/2.png" alt="" className="two-image" />
                  <p
                    className="inner-text1"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    Our mission at B-Alert is to redefine personal security. We
                    strive to empower individuals with cutting-edge technology,
                    providing instant alerts during emergencies and integrating
                    seamlessly with travel services for a safer and more secure
                    journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="bg_color center-vertically2">
          <div class="container">
            <h1 className="services-text">Our services</h1>
            <div class="row">
              <div
                class="col-md-6 col-xxl-7 col-xl-7 col-lg-7 col-sm-12 col-xs-12"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <h2 className="trip-text">Plan your trip</h2>
                <p className="introduce-text">
                  Plan Your Journey with Us: Experience seamless travel
                  arrangements, personalized itineraries, and expert guidance
                  for a memorable trip. Your adventure begins with meticulous
                  planning and our dedicated support.
                </p>
                <div className="btn-lg mt-4">
                  <Link
                    className="btn"
                    to={"/PlanTrip"}
                    onClick={scrollToTop}
                    style={{
                      backgroundColor: "#F9A738",
                      color: "#ffffff",
                      fontSize: "16px",
                      width: "20%",
                    }}
                  >
                    More
                  </Link>
                </div>
              </div>
              <div
                class="col-md-6 col-xxl-5 col-xl-5 col-lg-5 col-sm-12 col-xs-12 "
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                <img
                  src="./images/planatrip-2.png"
                  alt=""
                  className="plan_Trip_Image"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                <img
                  src="./images/insuranceee-2.png"
                  alt=""
                  className="plan_Trip_Image1"
                />
              </div>
              <div
                class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12 mt-3"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <h2 className="trip-text">Insurance</h2>
                <p className="introduce-text">
                  Secure your journey by recharging your FASTag with B-Alert.
                  Spend a minimum amount to unlock coverage, providing
                  unforeseeable protection you can trust.
                </p>
                <div className="btn-lg mt-4">
                  <Link
                    to={"/Insurance"}
                    onClick={scrollToTop}
                    className="btn "
                    style={{
                      backgroundColor: "#F9A738",
                      color: "#ffffff",
                      fontSize: "16px",
                      width: "20%",
                    }}
                  >
                    More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="bg-light">
            <img
              src="./images/Home_bar1.png"
              alt=""
              width="100%"
              style={{ marginTop: "1em" }}
            />
          </div>
        </section>
        <section class="container col-lg-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
          <div
            class="text-center"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
          >
            <p className="feedback-text">Feedback</p>
            <h1 className="client-text">
              What’s Our Clients Say <br />
              About Us
            </h1>
          </div>
          <div class="d-flex justify-content-around">
            <div className="row">
              <div
                className="col-md-4  col-xl-4 col-lg-4 col-sm-6 col-xs-6"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div class="card shadow" style={{ width: "18em" }}>
                  <div class="card-body">
                    <p class="card-text card-text1">
                      "Impressed with the app update! B-Alert's commitment to
                      enhancing user experience is evident. The new features
                      elevate personal security to a whole new level. 5 stars!
                    </p>
                    <div class="d-flex justify-content-around mt-5">
                      <div>
                        <h6 className="name-text">
                          App Update Testimonial - John Doe
                        </h6>
                        <img src="./images/stars.png" alt="" width="90" />
                      </div>
                      <img
                        src="./images/Icon11.jpg"
                        alt=""
                        width="75"
                        height="75"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-xs-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div class="card shadow" style={{ width: "18em" }}>
                  <div class="card-body">
                    <p class="card-text card-text1">
                      "Excited about B-Alert's future services! Their innovation
                      and dedication to user safety are commendable. I trust the
                      upcoming features will redefine personal security. 5
                      stars!
                    </p>
                    <div class="d-flex justify-content-around mt-5">
                      <div>
                        <h6 className="name-text">
                          Future Services Testimonial - Jane Smith
                        </h6>
                        <img src="./images/stars.png" alt="" width="90" />
                      </div>
                      <img
                        src="./images/Icon22.png"
                        alt=""
                        width="75"
                        height="75"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-xs-6"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div class="card shadow" style={{ width: "18em" }}>
                  <div class="card-body">
                    <p class="card-text card-text1">
                      "B-Alert consistently exceeds expectations with its
                      existing services. From instant alerts to seamless
                      integrations, it's an invaluable companion for personal
                      security. Well-deserved 5 stars!
                    </p>
                    <div class="d-flex justify-content-around mt-5">
                      <div>
                        <h6 className="name-text">
                          Existing Services Testimonial - Alex Johnson
                        </h6>
                        <img src="./images/stars.png" alt="" width="90" />
                      </div>
                      <img
                        src="./images/Icon33.png"
                        alt=""
                        width="75"
                        height="75"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container">
            <div class="bg_bg">
              <div class="store1">
                <a
                  href="https://play.google.com/store/apps/details?id=com.balert"
                  target="_blank"
                >
                  <img
                    src="./images/google play.png"
                    alt=""
                    className="app_Store_Img11"
                  />
                </a>
                &nbsp;&nbsp;
                <img
                  src="./images/app store.png"
                  alt=""
                  className="app_Store_Img11"
                />
              </div>
            </div>
          </div>
        </section>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

{
  /* <div class="store1">
                <a
                  href="https://play.google.com/store/apps/details?id=com.balert"
                  target="_blank">
                  <img
                    src="./images/Play_Store.png"
                    alt=""
                    className="app_Store_Img11"
                  />
                </a>
                <img
                  src="./images/App_Store.png"
                  alt=""
                  className="app_Store_Img12"
                />
              </div> */
}
export default Home;
