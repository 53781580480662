import React,{useEffect}from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
function GoodSamartian() {
    useEffect(()=>{
        AOS.init();
    },[]);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (
    <div class="sect_cls">
        <Header/>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-xxl-6 col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <img src="./images/vectors.png" alt="" className='Vector_Image' width="500" height="300"/><br></br>
                        <img src='./images/Subtract.png'/>
                        <div className='plan_bg'>
                        <h1 className='good-samartian-text'>Good Samaritan</h1>
                        <p className='plan-text1'>"Good Samaritan at its best. Our commitment to community welfare, charitable initiatives, and social responsibility aims to make a positive impact, fostering a better, more compassionate world for all."</p>
                        <Link to={'/Contact'} onClick={scrollToTop} class="btn btn-md mt-4" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a href="./Contact.html" style={{textDecoration:'none',color:'#FFFFFF'}}>Get Service</a></Link></div>
                    </div>
                    <div class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12 mt-5" >
                        <img src="./images/GS-2.png" alt="" className='planAtrip_Image' width="700" height="700"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="text-center" >
                    <p className='feedback-text' data-aos='zoom-in' data-aos-duration='1500'>OUR SERVICE</p>
                    <h1 className='plan-text2' data-aos='fade-right' data-aos-duration='1500'>- About Good Samaritan <span style={{color:'#000000'}}>-</span></h1>
                    <p class="small-text1" data-aos='fade-left' data-aos-duration='1500'>B-Alert proudly introduces the 'Good Samaritan' feature, a testament to our commitment to community safety. This innovative service empowers users to extend a helping hand during emergencies. The 'Good Samaritan' function connects nearby users, creating a supportive network for immediate assistance. Whether it's a flat tire, battery trouble, or a minor accident, users can offer or receive help, fostering a sense of camaraderie.</p>
                    <p class="small-text1 mb-5" data-aos='fade-left' data-aos-duration='1500'>By utilizing geolocation technology, 'Good Samaritan' enables swift responses, turning everyday commutes into opportunities for community support. B-Alert envisions a world where users feel secure, knowing that help is just a tap away. This feature exemplifies our dedication to not only personal safety but also creating a network of vigilant citizens ready to assist each other, transforming the road into a safer and more connected space. With 'Good Samaritan,' B-Alert invites users to be active contributors to a culture of mutual aid, turning moments of distress into opportunities for unity and support on the journey of life.</p>
                </div>
            </div>
        </section>
        <section>
            <div class="container mb-5">
                <div class="about_bg">
                    <div class="card1" data-aos='fade-right' data-aos-duration='1500'>
                        <div className='d-flex justify-content-between '>
                            <div className='faqs faq-pad'>
                                <h1 className='faqs'>FAQs</h1>
                                <p className='faq1'><span>Regarding the Service and More</span></p>
                            </div>
                            <div className='pad-3'>
                                <Link to={'/FAQ'} onClick={scrollToTop} class="btn btn-sm Go-Btn" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a className='go' style={{textDecoration:'none',color:'#FFFFFF'}}>Go&nbsp;<FontAwesomeIcon icon={faGreaterThan} size={5}/></a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class=" container mt-5">
            <div class="row">
                <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12 balert-left" data-aos="fade-right" data-aos-duration='1500'>
                    <h1 className='download-balert-text'>Why to download B ALERT?</h1>
                    <p className='software-text'>Introducing B-ALERT: India's groundbreaking personal security software.</p>
                    <div className='content'>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Schedule a trip and unlock most comfortable journey.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Insure your journey and enjoy effortless fun.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} /> &nbsp;&nbsp;&nbsp;Get
                            Road side assistance and more services at one click.</p>
                    </div>
                    <div class="d-flex content1">
                        <a href='https://play.google.com/store/apps/details?id=com.balert'><img src="./images/google play.png" alt="" className='app_Store_Img'/></a>&nbsp;&nbsp;
                        <img src="./images/app store.png" alt="" className='app_Store_Img'/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12" data-aos="zoom-in-left" data-aos-duration='1500'>
                    <img src="./images/Services_img.png" alt="" className='services_Image'/>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default GoodSamartian;
