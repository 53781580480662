import React,{useEffect}from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AOS from 'aos'
import 'aos/dist/aos.css'

function AIDent() {
    useEffect(()=>{
        AOS.init();
    },[]);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (
    <div class="sect_cls">
        <Header/>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                        <img src="./images/vectors.png" alt="" className='Vector_Image' width="500" height="300"/><br></br>
                        <img src='./images/Subtract.png'/>
                        <div className='plan_bg'>
                        <h1 className='plan-text'>AI Dent Detection</h1>
                        <p className='plan-text1'>"Experience unparalleled car care with AI Damage Detection. Our cutting-edge technology swiftly identifies and reports vehicle dents, ensuring prompt attention and preserving the pristine condition of your automobile effortlessly."</p>
                        <Link to={'/Contact'} onClick={scrollToTop} class="btn btn-md mt-4" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a href="./Contact.html" style={{textDecoration:'none',color:'#FFFFFF'}}>Get Service</a></Link></div>
                    </div>
                    <div class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12 mt-5">
                        <img src="./images/AIDentDetection1.png" className='planAtrip_Image' alt="" width="700" height="700"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="text-center" >
                    <p className='feedback-text' data-aos='zoom-in' data-aos-duration='1500'>OUR SERVICE</p>
                    <h1 className='plan-text2' data-aos='fade-right' data-aos-duration='1500'>- About AI Dent Detection <span style={{color:'#000000'}}>-</span>    </h1>
                    <p class="small-text1" data-aos='fade-left' data-aos-duration='1500'>"B-Alert presents a groundbreaking leap in car care with our revolutionary AI Dent Detection feature. Leveraging cutting-edge artificial intelligence, this advanced technology brings a new dimension to vehicle maintenance. It seamlessly identifies and reports dents with unmatched precision, ensuring immediate attention and preservation of your vehicle's aesthetic integrity.</p>
                    <p class="small-text1" data-aos='fade-left' data-aos-duration='1500'>The AI Dent Detection feature is designed to elevate your car care experience. It operates with exceptional accuracy, promptly notifying users of any imperfections, enabling swift action. This innovative solution caters to the demands of modern vehicle owners who seek a proactive and intelligent approach to maintenance.</p>
                    <p class="small-text1" data-aos='fade-left' data-aos-duration='1500'>Incorporating state-of-the-art algorithms, B-Alert's AI Dent Detection not only recognizes existing dents but also predicts potential risks based on driving patterns and external factors. This predictive capability adds a preventative layer to vehicle maintenance, minimizing the impact of unforeseen incidents.</p>
                    <p class="small-text1 mb-5" data-aos='fade-left' data-aos-duration='1500'>With B-Alert's AI Dent Detection, users can expect a smarter, more responsive approach to vehicle care. We prioritize not only the safety but also the aesthetic longevity of your automobile, ensuring a seamless and proactive solution that aligns with the demands of the modern driver."</p>
                </div>
            </div>
        </section>
        <section>
            <div class="container mb-5">
                <div class="about_bg">
                    <div class="card1" data-aos='fade-right' data-aos-duration='1500'>
                        <div className='d-flex justify-content-between '>
                            <div className='faqs faq-pad'>
                                <h1 className='faqs'>FAQs</h1>
                                <p className='faq1'><span>Regarding the Service and More</span></p>
                            </div>
                            <div className='pad-3'>
                                <Link to={'/FAQ'} onClick={scrollToTop} class="btn btn-sm Go-Btn" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a className='go' style={{textDecoration:'none',color:'#FFFFFF'}}>Go&nbsp;<FontAwesomeIcon icon={faGreaterThan} size={5}/></a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class=" container mt-5">
            <div class="row">
                <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12 balert-left" data-aos="fade-right" data-aos-duration='1500'>
                    <h1 className='download-balert-text'>Why to download B ALERT?</h1>
                    <p className='software-text'>Introducing B-ALERT: India's groundbreaking personal security software.</p>
                    <div className='content'>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Schedule a trip and unlock most comfortable journey.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Insure your journey and enjoy effortless fun.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} /> &nbsp;&nbsp;&nbsp;Get
                            Road side assistance and more services at one click.</p>
                    </div>
                    <div class="d-flex content1">
                    <a href='https://play.google.com/store/apps/details?id=com.balert'><img src="./images/google play.png" className='app_Store_Img' alt=""/></a>&nbsp;&nbsp;
                        <img src="./images/app store.png" alt="" className='app_Store_Img'/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12" data-aos="zoom-in-left" data-aos-duration='1500'>
                    <img src="./images/Services_img.png" alt="" className='services_Image'/>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default AIDent;
