import React,{useEffect}from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
function Insurance() {
    useEffect(()=>{
        AOS.init();
    },[]);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (
    <div class="sect_cls">
        <Header/>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-xxl-6 col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <img src="./images/vectors.png" alt="" className='Vector_Image' width="500" height="300"/><br></br>
                        <img src='./images/Subtract.png'/>
                        <div className='plan_bg'>
                        <h1 className='finance-text'>Insurance</h1>
                        <p className='plan-text1'>"Safeguard your journey with our comprehensive Insurance feature. Enjoy coverage for medical expenses, unforeseen events, and personalized assistance. Peace of mind and security tailored to your unique travel needs."</p>
                        <Link to={'/Contact'} onClick={scrollToTop} class="btn btn-md mt-4" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a href="./Contact.html" style={{textDecoration:'none',color:'#FFFFFF'}}>Get Service</a></Link></div>
                    </div>
                    <div class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12 mt-5">
                        <img src="./images/Insurance1.png" className='planAtrip_Image' alt="" width="700" height="700"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="text-center" >
                    <p className='feedback-text' data-aos='zoom-in' data-aos-duration='1500'>OUR SERVICE</p>
                    <h1 className='plan-text2' data-aos='fade-right' data-aos-duration='1500'>- About Insurance <span style={{color:'#000000'}}>-</span></h1>
                    <p class="small-text1 mb-5" data-aos='fade-left' data-aos-duration='1500'>"B-Alert redefines insurance by offering tailored, comprehensive coverage that goes beyond the conventional. Our innovative solutions seamlessly integrate with travel, providing an enhanced layer of security. With B-Alert, experience insurance that adapts to your unique needs, ensuring peace of mind and redefining your journey in a world where security is paramount."</p>
                </div>
            </div>
        </section>
        <section>
            <div class="container mb-5">
                <div class="about_bg">
                    <div class="card1" data-aos='fade-right' data-aos-duration='1500'>
                        <div className='d-flex justify-content-between '>
                            <div className='faqs faq-pad'>
                                <h1 className='faqs'>FAQs</h1>
                                <p className='faq1'><span>Regarding the Service and More</span></p>
                            </div>
                            <div className='pad-3'>
                                <Link to={'/FAQ'} onClick={scrollToTop} class="btn btn-sm Go-Btn" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a className='go' style={{textDecoration:'none',color:'#FFFFFF'}}>Go&nbsp;<FontAwesomeIcon icon={faGreaterThan} size={5}/></a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class=" container mt-5">
            <div class="row">
                <div class="col-xl-9 col-md-6 col-lg-9 col-xs-12 col-sm-12 balert-left" data-aos="fade-right" data-aos-duration='1500'>
                    <h1 className='download-balert-text'>Why to download B ALERT?</h1>
                    <p className='software-text'>Introducing B-ALERT: India's groundbreaking personal security software.</p>
                    <div className='content'>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Schedule a trip and unlock most comfortable journey.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Insure your journey and enjoy effortless fun.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} /> &nbsp;&nbsp;&nbsp;Get
                            Road side assistance and more services at one click.</p>
                    </div>
                    <div class="d-flex content1">
                        <a href='https://play.google.com/store/apps/details?id=com.balert'><img src="./images/google play.png" alt="" className='app_Store_Img'/></a>&nbsp;&nbsp;
                        <img src="./images/app store.png" alt="" className='app_Store_Img'/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12" data-aos="zoom-in-left" data-aos-duration='1500'>
                    <img src="./images/Services_img.png" alt="" className='services_Image'/>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default Insurance;
