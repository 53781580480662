import React, { useEffect,useState } from 'react'
import validator from 'validator'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot,faPhoneVolume,faEnvelope, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FaInstagram,FaTwitter,FaFacebook,FaYoutube,FaLinkedin,FaBuilding } from 'react-icons/fa';
import axios from 'axios';
import {API_URL} from '../Components/Constants';
import SuccessAlert from "../Components/SuccessAlert";

function Contact() {
    useEffect(()=>{
        AOS.init();
    },[]);

    const [isModalOpen, setIsModalOpen] = useState(false);
  const [Subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(1);
  const [name, setName] = useState("");
  const YourMessage = useState("");
  const [EmailError, setEmailError] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const validateEmail = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (validator.isEmail(emailValue)) {
      setEmailError("Valid Email :)");
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    formDataToSend.append("REQUEST_TYPE", "website");
    formDataToSend.append("NAME", name);
    formDataToSend.append("PHONE_NUMBER", "91" + phone);
    formDataToSend.append("EMAIL", email);
    formDataToSend.append("SUBJECT", Subject);
    formDataToSend.append("YOURMESSAGE", YourMessage);

    console.log(formDataToSend);
    try {
      axios
        .post(API_URL + "users/Contactus", formDataToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setSuccessMessage(res.data.message);
          setShowSuccess(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };
  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };
  return (
    <div>
      <div class="sect_cls1">
        <Header/>
        <div class="container mt-5">
            <div class="row">
                <div class="col-lg-7 col-xl-7 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center" data-aos='fade-right' data-aos-duration='1500'>
                    <div class="contact_background border border-dark shadow mb-5">
                        <div>
                            <div class="container col-md-12">
                                <h1 className='contact-text'>Contact <span class="us">Us</span></h1>
                                <form id="contactForm" onSubmit={handleSubmit}>
                                    <div class="form-group mt-4">
                                        <input type="text" class="form-control form-control-1" placeholder="Your Name"
                                            name="name" maxLength={30} value={name} onChange={(e) => setName(e.target.value)} required/>
                                    </div>
                                    <div class="form-group mt-4">
                                        <input type="email" class="form-control form-control-1" placeholder="Email"
                                         onChange={(e) => validateEmail(e)} required/>
                                    </div>
                                    {/* <div class="form-group mt-4">
                                        <input type="tel" class="form-control form-control-1" placeholder="Mobile Number"
                                            name="phone" minLength={10} maxLength={10} value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                                    </div>
                                    <div class="form-group mt-4">
                                        <input type="text" class="form-control form-control-1" placeholder="Subject" maxLength={15}
                                            name="Subject" value={Subject} onChange={(e) => setSubject(e.target.value)} required/>
                                    </div> */}
                                    <div class="form-group mt-4">
                                        <textarea class="form-control" id="" placeholder="Message"
                                            name="" rows="6" required></textarea>
                                    </div>
                                    <div class="form-check mt-4">
                                        <input type="checkbox" class="form-check-input" id="checkBox" name="checkBox"
                                            required/>
                                        <label class="form-check-label" for="checkBox">I would like to receive the
                                            newsletter.</label>
                                    </div>
                                    <div>
                                    <button class="btn btn-md mt-5" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}>
                                        Submit
                                    </button>
                                    <SuccessAlert
                                        show={showSuccess}
                                        onClose={() => setShowSuccess(false)}
                                        message="Contact details are submitted successfully!"
                                        onSubmitSuccess={() => {
                                        setShowSuccess(false);
                                        }}
                                    />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-8 col-sm-12 col-xs-12"  data-aos='fade-left' data-aos-duration='1500'>
                    <div class="">
                        <p className='get-touch'>Get in touch with us, we love to hear and respond to <br/>your messages.</p>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5253765500497!2d78.37664357420597!3d17.434550583460705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e37831f177%3A0x32d4499d34baaa78!2sT-Hub%20Phase%202!5e0!3m2!1sen!2sin!4v1709114044094!5m2!1sen!2sin"
                            width="400" height="350" style={{border:'0'}} className='map-margin' allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <div>
                            <p className='Company'>Buzzler Technologies Private Limited</p>
                            <p class="mt-2 get-touch"><FontAwesomeIcon icon={faLocationDot}/>&nbsp;&nbsp;T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao Nagar, <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madhapur, Hyderabad, Telangana 500081</p>
                            <p className='get-touch'><FontAwesomeIcon icon={faPhoneVolume}/>&nbsp;&nbsp;+91 91547 91547</p>
                            <p className='get-touch'><FontAwesomeIcon icon={faEnvelope}/>&nbsp;&nbsp;hello@balert.in</p>
                        </div>
                    </div>
                    <div class="text-end mb-5">
                        <a href="https://www.instagram.com/balert.in/" target='_blank'><FaInstagram size={20} color='#000000' class="justify-content-end"/></a>&nbsp;&nbsp; 
                        <a href="https://twitter.com/balert_in" target='_blank'><FaTwitter size={20} color='#000000'/></a>&nbsp;&nbsp;
                        <a href="https://www.facebook.com/balertofficial/" target='_blank'><FaFacebook size={20} color='#000000'/></a>&nbsp;&nbsp;
                        <a href='https://www.linkedin.com/company/balertofficial/' target='_blank'><FaLinkedin size={20} color='#000000'/></a>&nbsp;&nbsp;
                        <a href="" target='_blank'><FaYoutube size={20} color='#000000'/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Contact
