import React,{useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css'

function FAQ() {
    useEffect(()=>{
        AOS.init();
    },[]);
  return (
    <div>
      <div class="sect_cls">
        <header>
            <div class="container">
                <Header/>
            </div>
        </header>
        <section>
            <div class="container mb-5">
                <h1 className='faq-text1'>FAQ's</h1>
                <div class="container">
                    <div class="service_background shadow mt-4">
                        <div class="accordion-container">
                        <div className="accordion" id="accordionExample">
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:'#F9A738'}}>
                                    <p>How to add multiple Vehicles on my account?</p>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>You can add only one vehicle as of now, we will provide this feature in next updates. </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{color:'#F9A738'}}>
                                    Is my Data is Secure here?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Our system is designed one way communication to all aspects, so we assure on this for 100%, we will not exchange any data to anyone including Vechical number or state as well.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{color:'#F9A738'}}>
                                    Can I refer my friends on this?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>We are happy to help all, you can refer them in very simple model, Just type REFER on our BOT, it will allow you select the contacts from your contact list, so you can easily send to welcome message.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{color:'#F9A738'}}>
                                    How to become a Paid Super Heros?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>We have a vast free laser network in India, any how we are still looking for Super Hero’s on paid version as well, we will give great reward on immediate response on incident, you can simple register our network and show interest, we will call back you and register.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style={{color:'#F9A738'}}>
                                    I want to sell your Product in market?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>If you want to connect with us through API, you can send a email to hello@balert.in we will contact you back and make sure your integrations will happen asap. And we are offering best price for wholesalers, If you want to work as a TL , Agent , Send a mail with your team size we will give on our portal.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" style={{color:'#F9A738'}}>
                                    How can I report Incident?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Any register user can report this; you no need to register as a super hero. Just Click on Emergency button on Our BOT, or you simple type EMERGENCY on it, it will 3-4 Questions, like Please take the images to convince the Audit team and make sure genuine cases only populate. Like Number plate, Damage, Situation, etc..

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" style={{color:'#F9A738'}}>
                                    Will you provide Ambulance services only for accidents or any normal cases also?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Yes for all cases. We are market place for Ambulance services in Urban areas, we have tied up with multiple companies, on one click lead will be distributed to multiple private operators and they will assist you for same. Not a Free service.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight" style={{color:'#F9A738'}}>
                                    What about On Road assistance 24/7 in urban areas?
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>We are in process to connect with some companies on same; in future update we will bring this service also available in our system. We are trying to create aggregator platform all services.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine" style={{color:'#F9A738'}}>
                                    Only Super Hero’s get paid on this Incident Report?
                                </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Yes, It’s not about the money. It’s our responsibility to Inform to their loving people. Any how we will not disappoint you. We will give superior value for it. No worries about it.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen" style={{color:'#F9A738'}}>
                                    Why everything on Chat box, No Calls?
                                </button>
                            </h2>
                            <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Yes, Only on chat discussion. As we have emergency services.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven" style={{color:'#F9A738'}}>
                                    How does B-ALERT's accident and anomaly alert system work to monitor vehicles and personal well-being?
                                </button>
                            </h2>
                            <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>B-ALERT's accident and anomaly alert system utilizes advanced sensors and algorithms to monitor various vehicle parameters and the driver's well-being. It can detect anomalies like sudden braking, collisions, or accidents. When an incident occurs, the system immediately alerts the user's designated contact list through SMS or other communication channels.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve" style={{color:'#F9A738'}}>
                                    What are the benefits of using B-ALERT technology while driving in India?

                                </button>
                            </h2>
                            <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Using B-ALERT technology provides several benefits for drivers in India. It offers a safety net by promptly informing loved ones about any emergencies on the road. This quick alert system can expedite help and support in case of accidents. B-ALERT's ease of access and user-friendly interface make it a convenient option for all drivers.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen" style={{color:'#F9A738'}}>
                                    Does B-ALERT offer any additional features or services apart from accident alerts?

                                </button>
                            </h2>
                            <div id="collapseThirteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Yes, B-ALERT offers additional features to enhance user experience and safety. These may include real-time vehicle tracking, geofencing capabilities, and personalized driving behavior analysis to improve driving habits.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen" style={{color:'#F9A738'}}>
                                    Can you explain how to register for B-ALERT's system and access the discount coupons?
                                </button>
                            </h2>
                            <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Registering with B-ALERT requires only a mobile phone and an internet connection. Once you sign up, you can enter your email address to receive discount coupons. These coupons can save new customers anywhere between 20 to 40 percent on B-ALERT's services.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen" style={{color:'#F9A738'}}>
                                    How quickly can B-ALERT's system alert my designated contacts in case of an accident or anomaly?
                                </button>
                            </h2>
                            <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>B-ALERT's system is designed for immediate response. The system promptly alerts the user's designated contact list when it detects an accident or anomaly. This ensures that your loved ones are informed without delay.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen" style={{color:'#F9A738'}}>
                                    What certifications does B-ALERT hold, and how does it contribute to the company's credibility?

                                </button>
                            </h2>
                            <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>B-ALERT is certified by various business partners, which adds to the company's credibility and trustworthiness. These certifications demonstrate that B-ALERT meets industry standards and regulations, providing users with confidence in the service's reliability.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen" style={{color:'#F9A738'}}>
                                    Is B-ALERT's service limited to particular vehicle types, or can it be used with any vehicle?
                                </button>
                            </h2>
                            <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>B-ALERT's service is compatible with any vehicle, whether a car, motorcycle, or another motorized vehicle. As long as you have a mobile phone and an internet connection, you can use B-ALERT's system irrespective of your vehicle.


                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen" style={{color:'#F9A738'}}>
                                    Can I use B-ALERT even if I don't have a WhatsApp account? Are there alternative communication methods?
                                </button>
                            </h2>
                            <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Yes, you can use B-ALERT without a WhatsApp account. While WhatsApp is one of the communication methods, B-ALERT likely offers other options, such as Telegram, Web, Insta, Messager , Mobile app, to ensure users can receive alerts and notifications regardless of their preferred communication platform.


                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen" style={{color:'#F9A738'}}>
                                    What sets B-ALERT apart from other similar services in India that claim to provide vehicle monitoring?

                                </button>
                            </h2>
                            <div id="collapseNineteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>B-ALERT stands out due to its comprehensive accident and anomaly alert system, prompt response times, easy accessibility, and personalized customer support. Its discounts for new customers and certification from trusted business partners also add to its appeal and credibility.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty" style={{color:'#F9A738'}}>
                                    Does B-ALERT offer personalized support for users if they encounter issues or have specific inquiries?
                                </button>
                            </h2>
                            <div id="collapseTwenty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Yes, B-ALERT provides personalized support for its users. New customers can access a dedicated server with agents who can address inquiries, assist with registration, and offer assistance when needed. This ensures a smooth user experience and quick resolution of concerns or inquiries.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Footer/>
    </div>
  )
}

export default FAQ
