import React from 'react'
import { BrowserRouter,Link } from 'react-router-dom'
function Header() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div>
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <Link class="navbar-brand" to={'/'} onClick={scrollToTop}><img src="./images/logo-new.png" alt=""
                class="img-responsive logo_Image"/></Link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <Link class="nav-link active" onClick={scrollToTop} to={'/'}>Home</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link active" to={'/About'} onClick={scrollToTop}>About us</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link active" to={'/Services'} onClick={scrollToTop}>Services</Link>
                    </li>
                    <li class="nav-item rounded-pill" style={{backgroundColor:'#F9A738',paddingLeft:'5px',paddingRight:'5px'}}>
                        <Link class="nav-link active" style={{color:'#ffffff'}} to={'/Contact'} onClick={scrollToTop}>Contact Us</Link>
                    </li>
                </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
