import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { BrowserRouter,Link } from 'react-router-dom';

function Services() {
  useEffect(()=>{
    AOS.init();
  },[]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div class="sect_cls">
        <Header/>
        <section>
            <div className='Service_body_bg'>
            <div class="container center-vertically12" data-aos="zoom-in-up" data-aos-duration='1500'>
                <div class="text-center">
                    <h1 className='ourservices-text'>Our <span style={{color:'#000000'}}>Services</span></h1>
                    <p className='inner-text'>Elevate your security with B-Alert's cutting-edge services.Enjoy real-time emergency alerts,<br/>GPS tracking,and personalized safety features.Experience comprehensive <br/>protection tailored for your peace of mind on every journey.</p>
                    <p></p>
                    <div className='btn-lg mt-4'>
                        <Link to={'/Contact'} onClick={scrollToTop} className='btn button' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px'}}>Get in Touch</Link>
                    </div>
                </div>
            </div>
            </div>
            <div className='container'>
            <div class="map-margin1">
                    <img src="./images/map.png" alt="" width="100%" data-aos="flip-up" data-aos-duration='1500'/>
                </div>
                <hr class="line"/>
            </div>
        </section>
        <section class="mt-5">
            <div class="container">
                <h1 className='services-text1 text-center' data-aos="zoom-out-up" data-aos-duration='1500'>Our Services</h1>
                <div class="row">
                    <div class="col-md-12 col-xl-7 col-lg-7 col-sm-12 col-xs-12" data-aos="fade-right" data-aos-duration='1500'>
                        <h2 className='trip-text'>Plan your trip</h2>
                        <p className='introduce-text'>Plan Your Journey with Us: Experience seamless travel arrangements, personalized itineraries, and expert guidance for a memorable trip. Your adventure begins with meticulous planning and our dedicated support.</p>
                        <div className='btn-lg mt-4'>
                        <Link to={'/PlanTrip'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                    </div>
                    </div>
                    <div class="col-md-8 col-xl-5 col-lg-5 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/planatrip-2.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/insuranceee-2.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                    <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="fade-left" data-aos-duration='1500'>
                        <h2 className='trip-text'>Insurance</h2>
                        <p className='introduce-text'>Secure your journey by recharging your FASTag with B-Alert. Spend a minimum amount to unlock coverage, providing unforeseeable protection you can trust.</p>
                        <div className='btn-lg mt-4'>
                        <Link to={'/Insurance'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xl-7 col-lg-7 col-sm-12 col-xs-12" data-aos="fade-right" data-aos-duration='1500'>
                        <h2 className='trip-text'>Good Samaritan</h2>
                        <p className='introduce-text'>Introducing B-ALERT: India's groundbreaking personal security software. Instantly alert
                            your loved ones, police, and ambulances via WhatsApp during emergencies.
                        </p>
                        <div className='btn-lg mt-4'>
                        <Link to={'/GoodSamartian'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                    </div>
                    </div>
                    <div class="col-md-8 col-xl-5 col-lg-5 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/goodsamaritan-2.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/carpool-2.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                    <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="fade-left" data-aos-duration='1500'>
                        <h2 className='trip-text'>Car pooling</h2>
                        <p className='introduce-text'>Experience hassle-free commuting with B-Alert's carpooling feature. Connect with fellow travelers, share rides, and contribute to a greener environment. Enjoy convenience, savings, and community on your daily journeys.</p>
                        <div className='btn-lg mt-4'>
                        <Link to={'/CarPooling'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xl-7 col-lg-7 col-sm-12 col-xs-12" data-aos="fade-right" data-aos-duration='1500'>
                        <h2 className='trip-text'>AI Dent detection</h2>
                        <p className='introduce-text'>B-Alert's AI Dent Detection revolutionizes car care. Our advanced technology swiftly identifies and reports vehicle dents, ensuring prompt attention and maintaining the pristine condition of your automobile effortlessly.</p>
                        <div className='btn-lg mt-4'>
                        <Link to={'/AIDent'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                    </div>
                    </div>
                    <div class="col-md-8 col-xl-5 col-lg-5 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/ai-2.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/aura-2.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                    <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="fade-left" data-aos-duration='1500'>
                        <h2 className='trip-text'>A.U.R.A</h2>
                        <p className='introduce-text'>A.U.R.A (Alerts Upfront Road Assistance) by B-Alert provides real-time alerts for speed breakers, cameras, and speed zones, offering upfront information for a safer and informed driving experience.</p>
                        <div className='btn-lg mt-4'>
                            <Link to={'/Aura'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-xl-7 col-lg-7 col-sm-12 col-xs-12" data-aos="fade-right" data-aos-duration='1500'>
                        <h2 className='trip-text'>Finance</h2>
                        <p className='introduce-text'>"Empower your financial journey with B-Alert. Seamlessly manage expenses, track investments, and receive real-time alerts. Secure your financial future with our comprehensive and user-friendly finance solutions."</p>
                        <div className='btn-lg mt-4'>
                          <Link to={'/Finance'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                        </div>
                    </div>
                    <div class="col-md-8 col-xl-5 col-lg-5 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/Finance-1.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="zoom-in" data-aos-duration='1500'>
                        <img src="./images/carwash-2.png" alt="" width="600" height="600" className='plan_Trip_Image'/>
                    </div>
                    <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 col-xs-12" data-aos="fade-left" data-aos-duration='1500'>
                        <h2 className='trip-text'>Car wash</h2>
                        <p className='introduce-text'>"Experience convenience with B-Alert's doorstep car wash service. Book with ease and let us bring the shine to your doorstep. Hassle-free, reservation-based car care at your convenience.</p>
                        <div className='btn-lg mt-4'>
                            <Link to={'/Carwash'} onClick={scrollToTop} className='btn ' style={{backgroundColor:'#F9A738',color:'#ffffff',fontSize:'16px',width:'20%'}}>More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class=" container mt-5">
            <div class="row">
                <div class="col-md-6 col-lg-9 col-sm-12 col-xs-12 balert-left" data-aos="fade-right" data-aos-duration='1500'>
                    <h1 className='download-balert-text'>Why to download B ALERT?</h1>
                    <p className='software-text'>Introducing B-ALERT: India's groundbreaking personal security software.</p>
                    <div className='content'>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Schedule a trip and unlock most comfortable journey.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Insure your journey and enjoy effortless fun.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} /> &nbsp;&nbsp;&nbsp;Get
                            Road side assistance and more services at one click.</p>
                    </div>
                    <div class="d-flex content1">
                        <a href='https://play.google.com/store/apps/details?id=com.balert'><img src="./images/google play.png" alt="" className='app_Store_Img'/></a>&nbsp;&nbsp;
                        <img src="./images/app store.png" alt="" className='app_Store_Img'/>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 col-sm-12 col-xs-12">
                    <img src="./images/Services_img.png" alt="" className='services_Image'/>
                </div>
            </div>
        </section>
        <section>
            <div class="container mt-5 mb-5">
                <div class="p-3 card border-0 border-bottom border-dark shadow">
                    <div class="d-flex justify-content-between">
                        <h6 className='faq-text'>FAQ and more</h6>
                        <Link to={'/Contact'} onClick={scrollToTop} className='btn  rounded-pill' style={{backgroundColor:'#F3F1FF',fontFamily:'Montserrat'}}>contact us</Link>
                    </div>
                </div>
                <div class="service_background shadow mt-5">
                    <div class="accordion-container">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:'#F9A738'}}>
                                    How to add multiple Vehicles on my account?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>You can add only one vehicle as of now, we will provide this feature in next updates. </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{color:'#F9A738'}}>
                                    Is my Data is Secure here?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>Our system is designed one way communication to all aspects, so we assure on this for 100%, we will not exchange any data to anyone including Vechical number or state as well.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{color:'#F9A738'}}>
                                    Can I refer my friends on this?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>We are happy to help all, you can refer them in very simple model, Just type REFER on our BOT, it will allow you select the contacts from your contact list, so you can easily send to welcome message.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Link to={'/FAQ'} onClick={scrollToTop} className='btn btn-md rounded-pill p-2' style={{backgroundColor:'#F9A738',fontFamily:'Montserrat', float:'inline-end',color:'#FFFFFF',fontWeight:'bold'}}>More</Link>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default Services
