import React,{useEffect}from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';
function CarPooling() {
    useEffect(()=>{
        AOS.init();
    },[]);
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (
    <div class="sect_cls">
        <Header/>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                        <img src="./images/vectors.png" className='Vector_Image' alt="" width="500" height="300"/><br></br>
                        <img src='./images/Subtract.png'/>
                        <div className='plan_bg'>
                        <h1 className='plan-text'>Car Pooling</h1>
                        <p className='plan-text1'>"Transform your commute with our carpooling service. Connect with like-minded individuals, reduce costs, and contribute to a greener environment. Convenient, reliable, and community-driven transportation for a smarter, sustainable journey."</p>
                        <Link to={'/Contact'} onClick={scrollToTop} class="btn btn-md mt-4" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a href="./Contact.html" style={{textDecoration:'none',color:'#FFFFFF'}}>Get Service</a></Link></div>
                    </div>
                    <div class="col-md-6 col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12 mt-5">
                        <img src="./images/CarPooling1.png" className='planAtrip_Image' alt="" width="700" height="700"/>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="text-center" >
                    <p className='feedback-text' data-aos='zoom-in' data-aos-duration='1500'>OUR SERVICE</p>
                    <h1 className='plan-text2' data-aos='fade-right' data-aos-duration='1500'>- About Car Pooling <span style={{color:'#000000'}}>-</span></h1>
                    <p class="small-text1" data-aos='fade-left' data-aos-duration='1500'>B-Alert's Carpooling service redefines commuting by fostering a sustainable and community-driven approach to transportation. Designed for the modern world, this innovative feature seamlessly connects individuals with similar routes, promoting resource sharing and reducing environmental impact.</p>
                    <p class="small-text1" data-aos='fade-left' data-aos-duration='1500'>Through our platform, users can find reliable carpooling partners, transforming daily commutes into cost-effective and eco-friendly journeys. By maximizing vehicle occupancy, Carpooling contributes to reduced traffic congestion, lowered carbon emissions, and substantial cost savings for participants. The user-friendly reservation system ensures a hassle-free experience, enabling users to schedule rides conveniently.</p>
                    <p class="small-text1" data-aos='fade-left' data-aos-duration='1500'>Moreover, B-Alert's Carpooling goes beyond convenience—it cultivates a sense of community. Commuters share not just rides but also experiences, fostering a network of connections among like-minded individuals. With safety as our top priority, the service includes real-time alerts and updates, ensuring secure and trusted journeys.</p>
                    <p class="small-text1 mb-5" data-aos='fade-left' data-aos-duration='1500'>As B-Alert envisions a smarter, more sustainable future, Carpooling emerges as a pivotal solution, transforming daily commuting into a shared adventure, where individuals come together to create a positive impact on both their lives and the environment. Join us in redefining travel as we revolutionize the way we commute, one shared ride at a time.</p>
                </div>
            </div>
        </section>
        <section>
            <div class="container mb-5">
                <div class="about_bg">
                    <div class="card1" data-aos='fade-right' data-aos-duration='1500'>
                        <div className='d-flex justify-content-between '>
                            <div className='faqs faq-pad'>
                                <h1 className='faqs'>FAQs</h1>
                                <p className='faq1'><span>Regarding the Service and More</span></p>
                            </div>
                            <div className='pad-3'>
                                <Link to={'/FAQ'} onClick={scrollToTop} class="btn btn-sm Go-Btn" style={{backgroundColor:'#F9A738',color:'#ffffff',fontFamily:'Montserrat',fontWeight:'600'}}><a className='go' style={{textDecoration:'none',color:'#FFFFFF'}}>Go&nbsp;<FontAwesomeIcon icon={faGreaterThan} size={5}/></a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class=" container mt-5">
            <div class="row">
                <div class="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-xs-12 balert-left" data-aos="fade-right" data-aos-duration='1500'>
                    <h1 className='download-balert-text'>Why to download B ALERT?</h1>
                    <p className='software-text'>Introducing B-ALERT: India's groundbreaking personal security software.</p>
                    <div className='content'>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Schedule a trip and unlock most comfortable journey.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} />
                            &nbsp;&nbsp;&nbsp;Insure your journey and enjoy effortless fun.</p>
                        <p className='software-text'><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#F9A738' }} /> &nbsp;&nbsp;&nbsp;Get
                            Road side assistance and more services at one click.</p>
                    </div>
                    <div class="d-flex content1">
                    <a href='https://play.google.com/store/apps/details?id=com.balert'><img src="./images/google play.png" alt="" className='app_Store_Img'/></a>&nbsp;&nbsp;
                        <img src="./images/app store.png" alt="" className='app_Store_Img'/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12" data-aos="zoom-in-left" data-aos-duration='1500'>
                    <img src="./images/Services_img.png" alt="" className='services_Image'/>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default CarPooling;
