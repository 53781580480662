import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { BrowserRouter, Link } from "react-router-dom";
function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <footer class="footer_bg ">
        <div class="container">
          <div class="row">
            <div
              class="col col-md-2 col-xxl-2 col-xl-2 col-lg-2 col-sm-6 col-xs-12 ftlinks"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <ul>
                <h5 class="p-class">Product</h5>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.balert"
                    target="_blank"
                  >
                    APP
                  </a>
                </li>
                <li>
                  <Link to={"/Services"} onClick={scrollToTop}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link to={"/Services"} onClick={scrollToTop}>
                    Products
                  </Link>
                </li>
                <li>
                  <Link to={"/Services"} onClick={scrollToTop}>
                    Blogs
                  </Link>
                </li>
              </ul>
            </div>
            <div
              class="col col-md-4 col-xxl-4 col-xl-4 col-lg-4 col-sm-6 col-xs-12 ftlinks"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <h5 class="p-class" style={{ marginLeft: "1.3em" }}>
                Services
              </h5>
              <div className="d-flex">
                <ul>
                  <li>
                    <Link to={"/Aura"} onClick={scrollToTop}>
                      A.U.R.A
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Carwash"} onClick={scrollToTop}>
                      Car washing
                    </Link>
                  </li>
                  <li>
                    <Link to={"/CarPooling"} onClick={scrollToTop}>
                      Car pooling
                    </Link>
                  </li>
                  <li>
                    <Link to={"/AIDent"} onClick={scrollToTop}>
                      AI Dent Detection
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={"/Finance"} onClick={scrollToTop}>
                      Finance
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Insurance"} onClick={scrollToTop}>
                      Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to={"/PlanTrip"} onClick={scrollToTop}>
                      Plan your trip
                    </Link>
                  </li>
                  <li>
                    <Link to={"/GoodSamartian"} onClick={scrollToTop}>
                      Good Samaritan
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="col col-md-3 col-xxl-3 col-xl-3 col-lg-3 col-sm-6 col-xs-12 ftlinks"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <ul>
                <h5 class="p-class">Company</h5>
                <li>
                  <Link to={"/About"} onClick={scrollToTop}>
                    About us
                  </Link>
                </li>
                <li>
                  <Link to={"/Contact"} onClick={scrollToTop}>
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
            <div
              class="col col-md-3 col-xxl-3 col-xl-3 col-lg-3 col-sm-6 col-xs-12 ftlinks"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <ul>
                <h5 class="p-class">Other</h5>
                <li>
                  <Link to={"/"} onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to={"/FAQ"} onClick={scrollToTop}>
                    FAQ's
                  </Link>
                </li>
                <li>
                  <Link to={"/PrivacyPolicy"} onClick={scrollToTop}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to={"/TermsAndConditions"} onClick={scrollToTop}>
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link to={"/CancelRefundPolicy"} onClick={scrollToTop}>
                    Cancellation and Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <hr class="line_1" />
          <div className="container">
            <div class="row" style={{ marginTop: "5em" }}>
              {/* <div class="col col-md-12 col-xxl-7 col-xl-7 col-lg-7 col-sm-12 col-xs-12 d-flex">
                    <div className='justify-content-start'>
                    <img src='./images/T-Hub.png' alt='' width={150} height={75} />&nbsp;&nbsp;
                    <img src='./images/StartupIndia.png' alt='' width={150} height={50} className='mt-2'/>&nbsp;&nbsp;
                    <img src='./images/Niti.png' alt='' width={150} height={75}/>
                    </div>
                </div> */}
              <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2 ">
                <img src="./images/T-Hub.png" alt="" className="thub"/>
              </div>
              <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2 ">
                <img src="./images/StartupIndia.png" alt="" className="thub1"/>
              </div>
              <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2 ">
                <img src="./images/Niti.png" alt="" className="thub"/>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <ul>
                  <li class="social-icons">
                    <a
                      href="https://www.instagram.com/balert.in/"
                      target="_blank"
                    >
                      <FaInstagram size={30} color="black" />
                    </a>
                    <a
                      class="twitter"
                      href="https://twitter.com/balert_in"
                      target="_blank"
                    >
                      <FaTwitter size={30} color="black" />
                    </a>
                    <a
                      class="facebook"
                      href="https://www.facebook.com/balertofficial/"
                      target="_blank"
                    >
                      <FaFacebook size={30} color="black" />
                    </a>
                    <a
                      class="linkedin"
                      href="https://www.linkedin.com/company/balertofficial/"
                      target="_blank"
                    >
                      <FaLinkedin size={30} color="black" />
                    </a>
                    <a class="youtube" href="#" target="_blank">
                      <FaYoutube size={30} color="black" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div class="col col-md-12 col-xxl-5 col-xl-5 col-lg-5 col-sm-12 col-xs-12"></div> */}
            </div>
          </div>
          <div className="mt-5">
            <p className="reserved">© 2024 All Rights Reserved to B-ALERT</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
